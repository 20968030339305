<template>
  <div>
    <a-card>
      <template slot="title">
        <strong v-if="action === 'create'">Form Angsuran</strong>
        <strong v-if="action === 'update'">Update Angsuran</strong>
        <a-button size="default" type="danger" class="ml-2" @click="resetForm"
          >Back</a-button
        >
      </template>
      <div class="content">
        <div class="row pl-3 pr-3 pt-3 pb-3">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="row">
              <div
                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Tanggal</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-date-picker
                  style="width: 130px"
                  v-model="formData.tgl"
                  :allowClear="false"
                  format="DD-MM-YYYY"
                  @change="
                    (dateMoment, dateString) =>
                      changeDateInput(dateMoment, dateString, [
                        'formData',
                        'tgl',
                      ])
                  "
                />
              </div>
              <div
                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Rekening</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  class=""
                  style="width: 200px"
                  placeholder="Rekening"
                  v-model="formData.rekening"
                ></a-input>
                <!-- <a-select
                  class=""
                  show-search
                  placeholder="Ketikan Nama"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="maskInput.nama"
                  style="width: 30%"
                  @search="rekeningSearch"
                  @change="rekeningChange"
                  >
                  <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                  <a-select-option
                  v-for="(d, index) in listRekening"
                  :key="index"
                  >
                  {{ d.nama + " - " + d.alamat }}
                  </a-select-option>
                </a-select> -->
                <a-button
                  @click="carinasabahvisible = !carinasabahvisible"
                  class="ml-2 btn btn-outline-primary"
                >
                  <a-icon type="search"></a-icon> Cari Nasabah</a-button
                >
              </div>
              <div
                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Nama</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="true"
                  class=""
                  style="width: 400px"
                  placeholder="Nama"
                  v-model="maskInput.nama"
                ></a-input>
              </div>
              <div
                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Alamat</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="true"
                  class=""
                  style="width: 400px"
                  placeholder="Alamat"
                  v-model="maskInput.alamat"
                ></a-input>
              </div>
              <div
                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Pokok</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 30%"
                  v-model="formData.pokok"
                  placeholder="Input Pokok"
                />
              </div>
              <div
                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Margin</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 30%"
                  v-model="formData.bunga"
                  placeholder="Input Margin"
                />
              </div>
              <div
                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Denda</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 30%"
                  v-model="formData.denda"
                  placeholder="Input Denda"
                />
              </div>
              <div
                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Kas</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-select
                  class=""
                  show-search
                  placeholder="Pilih Produk Simpanan"
                  v-model="formData.golongandeposito"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  style="width: 250px"
                >
                  <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
                  <a-select-option value="K">
                    Kas
                  </a-select-option>
                  <a-select-option value="N">
                    Non-Kas
                  </a-select-option>
                </a-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                <a-button
                  size="default"
                  :class="
                    action === 'update' ? 'btn btn-warning' : 'btn btn-primary'
                  "
                  @click="handleSubmit"
                  >{{
                    action === "update" ? "Save Changes" : "Submit"
                  }}</a-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-card>
    <a-modal
      v-model="carinasabahvisible"
      :dialogStyle="{ top: '10px' }"
      :footer="null"
      :width="800"
    >
      <template slot="title">
        <strong>{{ carinasabahtitle }} </strong>
        <label style="margin: unset" v-if="selectedRekening !== ''">
          | Active Rekening: {{ selectedRekening }}</label
        >
      </template>
      <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark">Cancel</a-button>
          <a-button class="btn btn-outline-primary">Process</a-button>
        </template> -->
      <div class="row">
        <a-input
          class="ml-3"
          placeholder="Ketik Nama"
          v-model="input.nama"
          style="width: 200px"
          @keydown.enter="findData"
        ></a-input>
        <!-- <a-input
          class="ml-2"
          placeholder="Ketik Alamat"
          v-model="input.alamat"
          style="width: 200px"
          @keydown.enter="findData"
        ></a-input>
        <a-select
          style="width: 70px"
          placeholder="Kode"
          class="ml-2"
          v-model="input.cabang"
        >
          <a-select-option value="01">01</a-select-option>
        </a-select> -->
        <a-button
          v-if="selectedRekening !== ''"
          class="btn btn-outline-danger btn-sm ml-2"
          @click="clearRekening"
          >Clear Selected Rekening</a-button
        >
        <a-divider type="horizontal" style="margin: 8px 8px" />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <label
            v-if="
              table.tableatas.datatable.length !== null &&
              table.tableatas.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tableatas.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 300 }"
            :columns="table.tableatas.column"
            :dataSource="table.tableatas.datatable"
            :pagination="false"
            size="small"
            :customRow="customRow"
          >
            <!-- <template slot="actionButton" slot-scope="index, record">
              <a-button
                class="btn btn-outline-primary"
                @click="selectModalTable1(record)"
                >Pilih</a-button
              >
            </template> -->
          </a-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
          <label
            v-if="
              table.tablebawah.datatable.length !== null &&
              table.tablebawah.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tablebawah.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 200 }"
            :columns="table.tablebawah.column"
            :dataSource="table.tablebawah.datatable"
            :pagination="false"
            size="small"
            :customRow="customRowB"
          >
            <span slot="saldo" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
          <template slot="actionButton" slot-scope="index, record">
            <a-button class="btn btn-outline-primary" @click="selectModalTable2(record)">Pilih</a-button>
          </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
// import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    cleave: Cleave,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    this.lastFetchId = 0
    this.rekeningSearch = debounce(this.rekeningSearch, 800)
    return {
      editdata: JSON.parse(
        localStorage.getItem(
          'koperasisyariahedittransaksipembiayaanangsurandata',
        ),
      ),
      action: null,
      title: 'Register Nasabah',
      fetching: false,
      loadingMaster: false,
      maskInput: {
        nama: '',
        alamat: '',
      },
      formData: {
        rekening: '',
        tgl: moment(),
        pokok: '',
        bunga: '',
        denda: '',
        kas: '',
      },
      listKode: [],
      backuplistKode: [],
      listRekening: [],
      backuplistRekening: [],
      componentKey: 0,
      mastergolongandeposito: [],
      masterao: [],
      mastergolongankelompok: [],

      // Modal cari nasabah property
      carinasabahvisible: false,
      carinasabahtitle: 'Cari Nasabah',
      selectedRekening: '',
      input: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Kode',
              rowKey: 'kode',
              dataIndex: 'kode',
              scopedSlots: { customRender: 'kode' },
              width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              width: 150,
            },
            // {
            //   title: 'KTP',
            //   dataIndex: 'ktp',
            //   scopedSlots: { customRender: 'ktp' },
            //   width: 100,
            // },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
            // {
            //   title: '#',
            //   scopedSlots: { customRender: 'actionButton' },
            //   width: 70,
            // },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Jenis',
              dataIndex: 'jenis',
              scopedSlots: { customRender: 'jenis' },
              // width: 100,
            },
            {
              title: 'Rekening',
              dataIndex: 'rekening',
              scopedSlots: { customRender: 'rekening' },
              // width: 100,
            },
            {
              title: 'Saldo',
              dataIndex: 'saldo',
              scopedSlots: { customRender: 'saldo' },
              // width: 100,
            },
            {
              title: '#',
              scopedSlots: { customRender: 'actionButton' },
              // width: 70,
            },
          ],
          datatable: [],
        },
      },
      loading: false,
    }
  },
  created() {
    // this.getMaster()
    // console.log('this.editdata.id', this.editdata.id)
    // if (this.editdata.id !== '') {
    //   this.action = 'update'
    //   this.title = 'Update Nasabah'
    //   this.getEditData()
    // } else {
    this.action = 'create'
    this.title = 'Create Nasabah'
    // }
    // console.log('this.action', this.action)
  },
  methods: {
    moment,
    // Modal Cari Nasabah Function
    clearRekening() {
      this.selectedRekening = ''
    },
    async findData() {
      // console.log('this.formData.rekening', this.formData.rekening)
      var imbuhan = ''
      imbuhan += this.input.nama !== '' ? '?q=' + this.input.nama : ''
      imbuhan +=
        this.input.alamat !== ''
          ? this.input.nama !== ''
            ? '&Alamat=' + this.input.alamat
            : '?Alamat=' + this.input.alamat
          : ''
      imbuhan += this.input.cabang !== '' ? '&cabang=' + this.input.cabang : ''
      var res = await lou.customUrlGet2(
        'transaksi/pembiayaan/angsuran/cari_nama' + imbuhan,
        false,
      )
      if (res) {
        if (res.data.length === 0) {
          lou.shownotif('Info', 'Data tidak ditemukan!', 'warning')
          this.table.tableatas.datatable = []
        } else {
          lou.shownotif(
            'Info',
            'Ditemukan ' + res.data.length + ' data yang cocok!',
            'success',
          )
          this.table.tableatas.datatable = res.data
        }
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      if (!this.loading) {
        this.loading = true
        var res = await lou.customUrlGet2(
          'transaksi/pembiayaan/angsuran/cari_rekening?kode=' + record.kode,
          false,
        )
        if (res) {
          if (res.data.length === 0) {
            lou.shownotif('Info', 'Data tidak ditemukan!', 'warning')
            this.table.tablebawah.datatable = []
          } else {
            lou.shownotif(
              'Info',
              'Ditemukan ' + res.data.length + ' data yang cocok!',
              'success',
            )
            this.table.tablebawah.datatable = res.data
          }
          this.listKode = this.$g.clone(res.data)
          this.backuplistKode = this.$g.clone(res.data)
          this.loading = false
        }
        this.loading = false
      }
    },
    selectModalTable1(record) {
      this.selectedKodeRegister = record.kode
      this.formData.kode = record.kode
      this.maskInput.nama = record.nama
      this.maskInput.alamat = record.alamat
      this.nasabahDetail(record)
      this.carinasabahvisible = false
    },
    selectModalTable2(record) {
      // console.log('record', record)
      // this.selectedKodeRegister = record.kode
      // this.formData.kode = record.kode
      this.selectedRekening = record.rekening
      this.formData.rekening = record.rekening
      this.carinasabahvisible = false
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.selectedKodeRegister = record.kode
            this.formData.kode = record.kode
            this.maskInput.nama = record.nama
            this.maskInput.alamat = record.alamat
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // this.selectedKodeRegister = record.kode
            // this.formData.kode = record.kode
            // console.log('record', record)
            this.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.selectedRekening', this.selectedRekening)
            this.formData.rekening = record.rekening
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    // End of Modal Cari Nasabah Function

    fetchUser(value) {
      // console.log('fetching user', value)
      // var hasil = this.backupmasterKota.filter(x => x.city_name.toLowerCase().includes(nval))
      // console.log('hasil', hasil)
      if (value !== '') {
        var nval = value.toLowerCase()
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.masterKota = []
        this.fetching = true
        var res = []
        res = this.backupmasterKota.filter((x) =>
          x.city_name.toLowerCase().includes(nval),
        )
        if (res.length !== 0) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.masterKota = res.data
          this.componentKey += 1
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    async rekeningSearch(value) {
      if (value !== '' && value.length >= 3) {
        this.fetching = true
        // this.formData.kode = this.formData.cabang + this.formData.rekode
        var response = await lou.customUrlGet2(
          'transaksi/pembiayaan/angsuran/cari_nama?q=' + value,
        )
        if (response) {
          this.listRekening = this.$g.clone(response.data)
          this.backuplistRekening = this.$g.clone(response.data)
          this.fetching = false
          this.componentKey += 1
          // console.log('this.backupmasterKota', this.backupmasterKota)
        } else {
          this.fetching = false
        }
      }
    },
    rekeningChange() {
      // console.log('this.maskInput.nama', this.maskInput.nama)
      // console.log('this.listRekening', this.listRekening)
      this.formData.kode = this.listRekening[this.maskInput.nama].kode
      this.kodeChange()
    },
    async kodeChange() {
      this.fetching = true
      // this.formData.kode = this.formData.cabang + this.formData.rekode
      var response = await lou.customUrlGet2(
        'transaksi/pembiayaan/angsuran/cari_rekening?kode=' + this.formData.kode,
      )
      if (response) {
        this.listKode = this.$g.clone(response.data)
        this.backuplistKode = this.$g.clone(response.data)
        this.fetching = false
        this.componentKey += 1
        // console.log('this.backupmasterKota', this.backupmasterKota)
      } else {
        this.fetching = false
      }
    },
    changeDateInput(dateMoment, dateString, arrdata) {
      this[arrdata[0]][arrdata[1]] = dateMoment
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('arrdata', arrdata)
    },
    async getMaster() {
      this.loadingMaster = true
      var res = await lou.customUrlGet2('general/master/golongandeposito')
      var res1 = await lou.customUrlGet2('general/master/ao')
      var res2 = await lou.customUrlGet2('general/master/golongankelompok')
      if (res) {
        this.mastergolongandeposito = this.$g.clone(res.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
      if (res1) {
        this.masterao = this.$g.clone(res1.data)
      }
      if (res2) {
        this.mastergolongankelompok = this.$g.clone(res2.data)
      }
    },
    async handleSubmit() {
      // Should format date value before submit.
      var res = false
      var fd = this.$g.clone(this.formData)
      // console.log('fd', fd)
      // fd.rekening = fd.rekening === null || fd.rekening === undefined ? '' : this.formData.rekening
      fd.tgl =
        this.formData.tgl !== null
          ? this.formData.tgl.format('YYYY-MM-DD')
          : null
      // fd.tgllahir =
      //   this.formData.tgllahir !== null
      //     ? this.formData.tgllahir.format('YYYY-MM-DD')
      //     : null
      // fd.anggota = 1
      // fd.kodepos = parseInt(fd.kodepos)
      // fd.kodepos = fd.kodepos.toString()
      // if (this.action === 'update') {
      //   res = await lou.customUrlPut2('transaksi/pembiayaan/angsuran', fd, true)
      // } else {
      delete fd.id
      // delete fd.rekening
      res = await lou.customUrlPost2('transaksi/pembiayaan/angsuran', fd, true)
      // }
      if (res) {
        // lou.shownotif(
        //   'Success',
        //   'Data baru Berhasil ditambahkan dengan nomor Register: ' + res.data,
        //   'success',
        // )
        // Modal.confirm({
        //   title: 'Cetak Validasi?',
        //   content: 'Simpan berhasil!, Cetak Validasi?',
        //   okText: 'Yes',
        //   okType: 'primary',
        //   cancelText: 'No',
        //   onOk: async () => {
        //     var res1 = await lou.ngetDocumentPdf(
        //       'transaksi/pembiayaan/angsuran/cetak/' + res.data.id,
        //       false,
        //       false,
        //     )
        //     if (res1) {
        //       this.resetForm()
        //       // console.log('res1', res1)
        //       // console.log("Success", res1ponse);
        //       const blob = new Blob([res1], {
        //         type: 'application/pdf',
        //       })
        //       var iframe = this._printIframe
        //       var url = URL.createObjectURL(blob)
        //       if (!this._printIframe) {
        //         iframe = this._printIframe = document.createElement('iframe')
        //         document.body.appendChild(iframe)

        //         iframe.style.display = 'none'
        //         iframe.onload = function () {
        //           setTimeout(function () {
        //             iframe.focus()
        //             iframe.contentWindow.print()
        //           }, 1)
        //         }
        //       }

        //       iframe.src = url
        //       // const link = document.createElement('a')
        //       // link.href = URL.createObjectURL(blob)
        //       // // link.target = '_blank'
        //       // // link.download = filename

        //       // link.download = ''
        //       // link.click()
        //       // // const objectUrl = URL.createObjectURL(blob)
        //       // // console.log('objectUrl', objectUrl)
        //       // // this.amodal.ArrayBuffer = objectUrl
        //       // URL.revokeObjectURL(blob)
        //       setTimeout(() => {
        //         this.componentKey += 1
        //         // this.amodalvisible = true
        //       }, 500)
        //     }
        //   },
        //   onCancel: () => {
        this.resetForm()
        //   },
        // })
      }
    },
    getEditData() {
      // var res = await lou.customUrlGet2('transaksi/pembiayaan/angsuran', { where: { id: this.editdata.id } })
      // console.log('this.editdata', this.editdata)
      this.formData = this.$g.clone(this.editdata)
      this.maskInput.nama = this.editdata.nama
      this.maskInput.alamat = this.editdata.alamat
      // console.log('this.editdata', this.editdata)
      // this.maskInput.cabang = this.editdata.kode.slice(0, this.editdata.kode.indexOf('.'))
      // this.maskInput.rekode = this.editdata.kode.slice(this.editdata.kode.indexOf('.') + 1, this.editdata.kode.length)
      this.formData.tgl = moment(this.editdata.tgl, 'YYYY-MM-DD')
      // setTimeout(() => {
      //   this.formData.kodya = this.editdata.kodya
      //   this.kotaChange()
      // }, 100)
      // setTimeout(() => {
      //   this.formData.kecamatan = this.editdata.kecamatan
      //   this.kecamatanChange()
      // }, 300)
      // setTimeout(() => {
      //   this.formData.kelurahan = this.editdata.kelurahan
      //   this.kelurahanChange()
    },
    resetForm() {
      localStorage.removeItem(
        'koperasisyariahedittransaksipembiayaanangsurandata',
      )
      this.formData = {
        rekening: '',
        tgl: moment(),
        pokok: '',
        bunga: '',
        denda: '',
        kas: '',
      }
      this.$router.push('/koperasisyariah/transaksi/pembiayaan/angsuran/data')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
